/*
 *********************************UAT W/INMATE COGNITO CREDENTIALS***************************************
 */
const aws_mobile = {
	API: {
		graphql_endpoint: "https://3ltym7vd4bgnplihyjgbsrg3u4.appsync-api.us-east-1.amazonaws.com/graphql"
	},
	aws_appsync_authenticationType: "AWS_COGNITO_USER_POOLS",
	Auth: {
		region: "us-east-1",
		userPoolId: "us-east-1_G1tTiZfv6",
		identityPoolId: "us-east-1:3e4048cf-9436-4bd4-adc0-f6b0f9a3f364",
		userPoolWebClientId: "hdk4u95efike4hgovr8phlcv9",
	},
	Storage: {
		messageImgs: {
			bucket: "kiwichatappstack-kiwichatmsgimgsaa0ddb06-1ss9n1i1ajkin",
			region: "us-east-1",
		},
		grievanceAndForm: {
			bucket: "kiwichatappstack-kiwichatgrievanceandformfilesed8-qpxt5fvgg8fb",
			region: "us-east-1",
		},
		bulletins: {
			bucket: "kiwichatappstack-kiwichatbulletinfilesfea9a418-qr3kgx85kgkf",
			region: "us-east-1",
		},
	},
	kiwi: {
		refresh_token: "",
		logLevel: 'DEBUG',
		clientType: 'INMATE_ANDROID',
	},
	environment: {
		env: "UAT"
	},
};

export default aws_mobile;